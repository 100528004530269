/* Extra Small Devices (Phones, Portrait Mode) */
@media (max-width: 575px) {

    .banner {
        padding: 50px 20px 20px 20px;
        width: 100%;
        height: 70%;
    }

    .posterShow {
        height: 100%;
        width: 100%;
    }

    .packagePosterShow {
        height: 100%;
        width: 100%;
    }

    .learningLeftPannel {
        height: 100%;
        width: 70%;
        position: absolute;
    }

    .learningLeftPannelData {
        height: 80%;
        overflow: auto;
        padding: 20px;
    }

    .learningRightPannel {
        height: 350px;
        width: 100%;
    }
}

/* Small Devices (Phones, Landscape Mode to Small Tablets) */
@media (min-width: 576px) and (max-width: 767px) {

    .banner {
        padding: 50px 20px 20px 20px;
        width: 100%;
        height: 70%;
    }

    .card {
        max-width: 100%;
    }

    .posterShow {
        height: 100%;
        width: 100%;
    }

    .packagePosterShow {
        height: 100%;
        width: 100%;
    }

    .learningLeftPannel {
        height: 100%;
        width: 70%;
        position: absolute;
    }

    .learningLeftPannelData {
        height: 80%;
        overflow: auto;
        padding: 20px;
    }

    .learningRightPannel {
        height: 350px;
        width: 100%;
    }
}

/* Medium Devices (Tablets) */
@media (min-width: 768px) and (max-width: 991px) {
    /* Styles for medium devices */

    .banner {
        padding: 50px 20px 20px 20px;
        width: 100%;
        height: 100%;
    }

    .posterShow {
        height: 100%;
        width: 100%;
    }

    .packagePosterShow {
        height: 100%;
        width: 100%;
    }

    .learningLeftPannel {
        height: 100%;
        width: 70%;
        position: absolute;
    }

    .learningLeftPannelData {
        height: 80%;
        overflow: auto;
        padding: 20px;
    }

    .learningRightPannel {
        height: 350px;
        width: 100%;
    }
}

/* Large Devices (Laptops, Small Desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
    /* Styles for large devices */

    .banner {
        padding: 50px 5% 20px 5%;
        width: 100%;
        height: 100%;
    }

    .posterShow {
        height: 500px;
        width: 100%;
    }

    .packagePosterShow {
        height: 100%;
        width: 100%;
    }

    .learningLeftPannel {
        height: 700px;
        width: 30%;
    }

    .learningLeftPannelData {
        height: 80%;
        overflow: auto;
        padding: 20px;
    }

    .learningRightPannel {
        height: 100%;
        width: 100%;
    }

    .learningPannel {
        display: flex;
    }
}

/* Extra Large Devices (Large Screens, Desktops) */
@media (min-width: 1200px) {

    .banner {
        padding: 50px 20px 20px 20px;
        width: 100%;
        height: 100%;
    }

    .posterShow {
        height: 100%;
        width: 100%;
    }

    .packagePosterShow {
        height: 1000px;
        width: 100%;
    }

    .learningLeftPannel {
        height: 700px;
        width: 30%;
    }

    .learningLeftPannelData {
        height: 80%;
        overflow: auto;
        padding: 20px;
    }

    .learningRightPannel {
        height: 100%;
        width: 100%;
    }

    .learningPannel {
        display: flex;
    }
}