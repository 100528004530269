.income {
    height: 100px;
    width: 100%;
    border-radius: 25px;
    margin-top: 10px;
    margin-bottom: 30px;
    box-shadow: 10px 10px 10px 10px black;
    border-style: inset;
    border-color: white;
}

.incomePayout {
  height: 100px;
  border-radius: 25px;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: 10px 10px 10px 10px black;
  border-style: inset;
  border-color: white;
}

.todayIncome {
    background-image: linear-gradient(to right,
            #1f0215, #360424, #540638, #75084e, #75084e, #540638, #360424);
   

}

.sevenIncome {
    background-image: linear-gradient(to right,
            #031826, #05253b, #07324f, #0c5485, #0c5485, #07324f, #05253b);
}

.monthIncome {
    background-image: linear-gradient(to right,
    #031b1c, #063638, #0a595c, #0e7478, #0e7478, #0a595c, #063638);
}

.totalIncome {
    background-image: linear-gradient(to right,
    #14021f, #240438, #360754, #46096e, #46096e, #360754, #240438);
}

.unpaidIncome {
    background-image: linear-gradient(to right,
            #011410, #021f19, #044033, #045745, #045745, #044033, #021f19);
}

.sevenTurnover {
  background-image: linear-gradient(to right,
  #031826, #05253b, #07324f, #0c5485, #0c5485, #07324f, #05253b);
}

.monthlyTurnover {
  background-image: linear-gradient(to right,
    #031b1c, #063638, #0a595c, #0e7478, #0e7478, #0a595c, #063638);
}

.totalTurnover {
  background-image: linear-gradient(to right,
  #14021f, #240438, #360754, #46096e, #46096e, #360754, #240438);
}

.dashboardProfile{
    height: auto;
    width: 100%;
    padding: 30px;
    background-image: linear-gradient(#c5d2d6,#343434);
}

.dashboardProfileForStatic {
  margin-top: 9%;
}

/* Mobile styles (for smaller screens) */
@media (max-width: 767px) {
  .dashboardProfileForStatic {
    margin-top: 50px; /* Adjust this value for mobile */
  }
}

.loaderVideo {
  width: 100%;
}

/* Media query for laptops */
@media (min-width: 1024px) {
  .loaderVideo {
    width: 90%;
  }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .loaderVideo {
    width: 100%;
  }
}

.headerBackground{
    background-image: linear-gradient(to top,
    #f8b65f, #4b5375);
}


.headerBackgroundReverse{
  background-image: linear-gradient(to top,
  #4b5375,#f8b65f);
}


.headerBackgroundWithShades{
  background-image: linear-gradient(to right,
  #11231B, #1B3228, #294136, #395147, #395147, #294136, #1B3228);
}

.headerBackgroundWithShadesTwo {
  background-image: linear-gradient(to right,
  #031b1c, #063638, #0a595c, #0e7478, #0e7478, #0a595c, #063638);
}

.headerBackgroundImg{
    background-image: url('B:\Growniverse\GrowniverseUi\src\Photos\Background\spacebg.jpg');
}

/* Marquee.css */
.marquee {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
  }
  
  .marquee span {
    display: inline-block;
    animation: scroll 10s linear infinite;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  .directpassive {
    background-image: linear-gradient(to right,
    #4b5375,#4E5677,#46517F,#46517F,#4E5677);
  }
  
  @media (min-width: 1024px) {
    .imageInPackage {
      height: 200px;
      width: 100%;
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    .imageInPackage {
      height: 200px;
      width: 100%;
    }
  }

  @media (min-width: 1024px) {
    .boxblockorflex {
      display: flex;
    }
  }
  
  /* Media query for mobile devices */
  @media (max-width: 767px) {
    .boxblockorflex {
      display: block;
    }
  }

    @media (min-width: 1024px) {
      .trainingImage {
        height: 500px;
        width: 100%;
      }
    }
    
    /* Media query for mobile devices */
    @media (max-width: 767px) {
      .trainingImage {
        height: 100%;
        width: 100%;
      }
    }

    .avatar-round {
      border-radius: 50%; /* Makes the avatar round */
      width: 50px;
      height: 50px;
      background-color: red;
      display: flex;
  }