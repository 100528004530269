.testimonial-section {
    background-image: url('https://themes.potenzaglobalsolutions.com/html/guruma/images/bg/09.png');
}
.testimonial-section h2 {
    color: #fff;
}
.testimonial-section .testimoni-user{
    height: 100px;
    width: 100px;
}
.testimonial-section .card{
    position: relative;
    overflow: visible;
    top: 80px;
    padding-top: 50px;
    border: 1px solid #ddd;
}
.testimonial-section .card img{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50px);
    top: 0;
    border-radius: 100%;
}
