@media (max-width: 1600px) {
  .banner-04 {
    height: 679px;
  }

  .banner-04 figure {
    width: 939px;
  }
  .header .navbar-brand {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 170px;
    flex: 0 0 170px;
  }

  .header .navbar .nav-item {
    margin-right: 15px;
  }

  .header .search-category {
    margin-right: 20px;
  }

  .header .search-category .form-control {
    width: 230px;
  }

  .course-category .select2-container {
    width: 120px !important;
  }

  .navbar .dropdown>.dropdown-menu li>a {
    letter-spacing: 0;
  }
}
@media (min-width: 1200px) {
  .swiper-pagination.swiper-pagination-left {
    max-width: 1270px;
  }
  .container {
    max-width: 1170px;
  }
  .navbar-nav .mega-menu {
    position: static;
  }

  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 0px;
    border: none;
    left: 100%;
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, transform;
    transition-property: opacity, visibility, transform, -webkit-transform;
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
    box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
    visibility: hidden;
    opacity: 0;
    display: block !important;
    border-radius: 3px;
  }

  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu {
    left: 100%;
    right: auto;
    -webkit-transform: translate3d(0px, 10px, 0);
    transform: translate3d(0px, 10px, 0);
  }

  .header .navbar .dropdown-menu .dropdown-submenu .dropdown-menu.left-side {
    right: 100%;
    left: auto;
    -webkit-transform: translate3d(-15px, 10px, 0);
    transform: translate3d(-15px, 10px, 0);
  }

  .header .navbar .dropdown-menu.megamenu {
    left: 50%;
  }

  .header .navbar .dropdown:hover>.dropdown-menu {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1;
    padding: 10px 0px;
  }

  .header .navbar li:hover>ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }
}

@media (max-width: 1199px) {
  .header .navbar-brand {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
  }
  .social-icon ul li {
    margin: 0px 10px;
  }

  .header .navbar .nav-item .shop-bg {
    background-image: inherit;
    border: none;
  }

  .header .navbar .dropdown-menu {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .header .search-category {
    display: none;
  }

  .navbar .dropdown>.dropdown-menu li>a {
    letter-spacing: 0;
  }

  .navbar .dropdown>.dropdown-menu li>a {
    letter-spacing: 0;
  }
  .my-shuffle-container.grid-4 .grid-item {
    width: 33.3333%;
  }
  .course-filter .course-short .select2-container {
    width: 152px !important;
  }
}
@media (max-width: 991px) {
  .section-title {
    margin-bottom: 30px;
  }

  .social-icon-round ul li {
    margin: 0px 5px;
  }
  .swiper-pagination.swiper-pagination-left {
    max-width: 960px;
  }
  .header .navbar-collapse {
    position: absolute;
    top: 100%;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    left: 0;
    margin-left: 0px;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
    box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  }

  .header .navbar {
    padding: 20px 0px;
  }

  .header .navbar .navbar-nav {
    -webkit-box-align: initial;
    -ms-flex-align: initial;
    align-items: initial;
  }

  .header .navbar .navbar-nav .nav-link {
    color: #1c1a1f;
    padding: 10px 20px;
  }

  .header .navbar .navbar-nav .nav-link i {
    position: absolute;
    right: 20px;
  }

  .header .navbar .dropdown-menu {
    margin: 0px;
    font-size: 14px;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #f6f6f6;
    padding: 14px 10px;
    font-size: 13px;
  }

  .header .navbar .navbar-toggler {
    position: absolute;
    right: 0px;
    padding-right: 0;
    color: #ffffff;
  }

  .header .navbar .navbar-toggler:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .header .navbar .woo-action {
    margin-right: 40px;
  }

  .header .navbar-brand img {
    height: 40px;
  }

  .header.default-transparent .navbar .navbar-nav .nav-link {
    color: #1c1a1f;
  }

  .header.is-sticky .navbar {
    padding: 15px 0px;
  }

  .header .navbar {
    position: relative;
    background: #1c1a1f;
  }

  .header .navbar .navbar-nav .nav-item {
    margin-right: 0;
    border-bottom: 1px solid #f6f6f6;
  }

  .header .navbar .navbar-nav .nav-link {
    color: #1c1a1f;
    padding: 12px;
  }
  .header.default-transparent.default-transparent-03 .navbar-brand {
    display: none;
  }

  .header.default-transparent.default-transparent-03 .navbar-brand-sticky {
    display: block;
  }

  .header.default-transparent.default-transparent-03 .woo-action ul li>a {
    color: #ffffff;
  }

  .header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-link {
    color: #1c1a1f;
  }
  .header .navbar .dropdown-menu.dropdown-menu-md {
    min-width: 400px;
  }

  .header .navbar .dropdown-menu.dropdown-menu-lg {
    min-width: 600px;
  }
  .owl-carousel .owl-nav .owl-next {
    right: 0;
  }

  .owl-carousel .owl-nav .owl-prev {
    left: 0;
  }
  .header .navbar .navbar-nav li>a {
    padding: 3px 15px;
  }
  .footer .footer-title {
    margin-bottom: 30px;
  }

  .h1,
  h1 {
    font-size: 42px;
    line-height: 52px;
  }

  .h2,
  h2 {
    font-size: 34px;
    line-height: 44px;
  }

  .h3,
  h3 {
    font-size: 32px;
    line-height: 42px;
  }

  .h4,
  h4 {
    font-size: 24px;
    line-height: 34px;
  }

  .h5,
  h5 {
    font-size: 22px;
    line-height: 32px;
  }

  /* Inner header */
  .inner-header {
    padding: 50px 0;
  }

  .inner-header .breadcrumb-title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .widgets .widget {
    margin-bottom: 30px;
  }
  .section-title {
    margin-bottom: 20px;
  }
  .swiper-pagination.swiper-pagination-left {
    max-width: 720px;
  }
  /* Home 02 */
  .slider-02 .slide-02 {
    background-position: right 0;
  }

  /* Home 03 */
  .slider-03 .swiper-slide {
    height: 420px;
  }

  .slider-03 .banner-content img {
    display: none;
  }

  .swiper-pagination .swiper-pagination-bullet {
    width: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .swiper-pagination.swiper-pagination-left {
    padding-left: 30px;
  }

  /* Home 04 */
  .banner-04 {
    height: 400px;
  }

  .banner-04 .content {
    margin-top: 70px;
  }

  .banner-04 .content h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .banner-04 figure {
    display: none;
  }
  .my-shuffle-container.grid-3 .grid-item {
    width: 50%;
  }

  .my-shuffle-container.grid-4 .grid-item {
    width: 50%;
  }
  .h1,
  h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .h2,
  h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .h3,
  h3 {
    font-size: 26px;
    line-height: 36px;
  }

  .h4,
  h4 {
    font-size: 22px;
    line-height: 32px;
  }

  .h5,
  h5 {
    font-size: 20px;
    line-height: 30px;
  }

  .h6,
  h6 {
    font-size: 16px;
    line-height: 26px;
  }

  /* Inner header */
  .inner-header {
    padding: 100px 0;
  }

  .inner-header .breadcrumb-title {
    font-size: 40px;
  }

  .image-wrapper,
  .carousel-item,
  .carousel-item img,
  .image-wrapper:before {
    height: calc(100vh + 20px);
  }

  .banner-content {
    width: 80%;
  }

  a.logo {
    color: #fff !important;
  }
}

@media (max-width: 575px) {
  .swiper-pagination.swiper-pagination-left {
    max-width: 510px;
  }
  .form-inline .btn {
    position: inherit;
  }
  /* Header */
  .header .navbar {
    padding: 15px 0px;
  }

  .header .navbar .navbar-brand {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 130px;
    flex: 0 0 130px;
    padding: 5px 0px;
  }

  .header .navbar .woo-action {
    margin-right: 30px;
  }

  .header .navbar .woo-action ul li> {
    line-height: 1;
  }

  .header .navbar .woo-action ul li>a {
    font-size: 0px;
  }

  .header.is-sticky .navbar {
    padding: 10px 0px;
  }
  .course-details .media {
    display: inherit;
  }

  .course-details .media img {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .course-list .course .course-img img {
    border-radius: 3px 3px 0px 0px;
  }

  .course-filter .course-short .select2-container {
    margin-left: 0;
  }
  .my-shuffle-container.grid-3 .grid-item {
    width: 100%;
  }

  .my-shuffle-container.grid-4 .grid-item {
    width: 100%;
  }
    .footer-link {
      display: block;
    }
  
    .footer-link ul li:first-child a {
      padding-top: 7px;
    }
  
    .footer-link ul li:last-child a {
      padding-bottom: 7px;
    }

  .h1,
  h1 {
    font-size: 30px;
    line-height: 40px;
  }

  .h2,
  h2 {
    font-size: 26px;
    line-height: 36px;
  }

  .h3,
  h3 {
    font-size: 24px;
    line-height: 34px;
  }

  /* Inner header */
  .inner-header {
    padding: 50px 0;
  }

  .inner-header .breadcrumb {
    margin: 0 auto;
    text-align: center;
  }

  .inner-header .breadcrumb-title {
    font-size: 30px;
  }
}

/*****************************
  Responsive
*****************************/
@media (min-width: 992px) {
  .text-lg-white {
    color: white !important;
  }
}


@media (max-width: 575px) {
  .navigation .nav-links .nav-previous {
    width: 100%;
  }

  .navigation .nav-links .nav-next {
    width: 100%;
    margin-top: 5px;
  }

  .pagination .page-item .page-link {
    min-width: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}