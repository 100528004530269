body {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #666;
  overflow-x: hidden;

}
.fz12 {
  font-size: 12px;
}
.fz13 {
  font-size: 13px;
}
.fz14 {
  font-size: 14px;
}
.fz15 {
  font-size: 15px;
}
.fz16 {
  font-size: 16px;
}
.fz17 {
  font-size: 17px;
}
.fz18 {
  font-size: 18px;
}
.fz19 {
  font-size: 19px;
}
.fz20 {
  font-size: 20px;
}
.fz21 {
  font-size: 21px;
}
.fz22 {
  font-size: 22px;
}
.fz23 {
  font-size: 23px;
}
.fz24 {
  font-size: 24px;
}
.fz25 {
  font-size: 25px;
}
.fz26 {
  font-size: 26px;
}
.fz27 {
  font-size: 27px;
}
.fz28 {
  font-size: 28px;
}
.fz29 {
  font-size: 29px;
}
.fz30 {
  font-size: 30px;
} 

a {
  outline: medium none !important;
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none!important;
}

a:focus {
  color: #1c1a1f;
  text-decoration: none !important;
}

a:hover {
  color: #1c1a1f;
  text-decoration: none !important;
}

i {
  line-height: 1;
}

hr {
  border-color: #eaeaea !important;
}

input {
  outline: medium none !important;
  color: #000;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  color: #1c1a1f;
  margin-top: 0px;
  letter-spacing: -1px;
}

.h1 a,
h1 a,
.h2 a,
h2 a,
.h3 a,
h3 a,
.h4 a,
h4 a,
.h5 a,
h5 a,
.h6 a,
h6 a {
  color: inherit;
}

label {
  font-weight: normal;
}

.h1,
h1 {
  font-size: 72px;
  line-height: 82px;
}

.h2,
h2 {
  font-size: 36px;
  line-height: 46px;
}

.h3,
h3 {
  font-size: 30px;
  line-height: 40px;
}

.h4,
h4 {
  font-size: 24px;
  line-height: 34px;
}

.h5,
h5 {
  font-size: 20px;
  line-height: 30px;
}

.h6,
h6 {
  font-size: 18px;
  line-height: 28px;
}

.lead {
  font-size: 16px !important;
  line-height: 26px;
}

small,
.small {
  font-size: 12px;
  font-weight: 500;
}

*::-moz-selection {
  background: #000;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #000;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #000;
  color: #ffffff;
  text-shadow: none;
}

/* Flaticon */
[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  margin-left: 0;
  font-size: inherit;
}

/* Inner header */
.inner-header {
  padding: 60px 0;
}

.inner-header .breadcrumb {
  background: transparent;
  margin-bottom: 0;
  padding: 0;
}

.inner-header .breadcrumb .breadcrumb-item {
  font-size: 13px;
  font-weight: 600;
  padding-left: 10px;
}

.inner-header .breadcrumb .breadcrumb-item a {
  color: #000;
}

.inner-header .breadcrumb .breadcrumb-item a:hover {
  color: #ffffff;
}

.inner-header .breadcrumb .breadcrumb-item.active {
  color: #ffffff;
}

.inner-header .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  content: "\f105";
  font-family: "Font Awesome 5 free";
  padding-right: 10px;
  color: #ffffff;
}

.inner-header .breadcrumb-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
}

/*****************************
  Helper Classes
*****************************/
/* Color */
.bg-primary .text-primary {
  color: #fff!important ;
}

.text-white {
  color: #ffffff;
}

.text-dark {
  color: #1c1a1f !important;
}

.text-light {
  color: #666666 !important;
}

.text-light-gray {
  color: #cccccc !important;
}

.text-muted {
  color: #999999 !important;
}

.text-body {
  color: #666666 !important;
}

/* Background */
.bg-primary {
  background-color: #262626 !important;
  color: #fff;
}

.bg-dark {
  background-color: #1c1a1f !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-light {
  background-color: #f9f9f9 !important;
}

.bg-success-soft {
  background: rgba(10, 187, 10, 0.1) !important;
}

.bg-danger-soft {
  background: rgba(245, 38, 38, 0.1) !important;
}

/* Box Shadow */
.box-shadow {
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
}

.box-shadow-lg {
  -webkit-box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
}

/* Border Radius */
.rounded {
  border-radius: 50px !important;
}

.rounded-sm {
  border-radius: 3px !important;
}

/* Font Weight */
.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

/* Border */
.border-primary {
  border-color: #000 !important;
}

.border-dark {
  border-color: #1c1a1f !important;
}

.border-gray {
  border-color: #999999 !important;
}

.border-color {
  border: 1px solid #eaeaea;
}

.border-color-2 {
  border: 1px solid #f9f9f9;
}

.border-top {
  border-color: #eaeaea !important;
}

.border-bottom {
  border-color: #eaeaea !important;
}

/* left right top */
.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

/* Border width */
.border {
  border-color: #eaeaea !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

/* Page section padding */
.space-ptb {
  padding: 100px 0;
}

.space-pt {
  padding-top: 100px;
}

.space-pb {
  padding-bottom: 100px;
}

.space-sm-ptb {
  padding: 70px 0;
}

.space-sm-pt {
  padding-top: 70px;
}

.space-sm-pb {
  padding-bottom: 70px;
}

.h-100vh {
  height: 100vh !important;
}

/* Half Overlay */
.half-overlay:before {
  content: "";
  background: #1c1a1f;
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  border-radius: 3px;
}

.half-overlay.left-position:before {
  left: 0;
}

.half-overlay.right-position:before {
  right: 0;
}

.bg-overlay-left-100 {
  position: relative;
}

.bg-overlay-left-100:before {
  position: absolute;
  content: "";
  top: 0;
  left: -100%;
  background-color: #1c1a1f;
  width: 100%;
  height: 100%;
}

.bg-overlay-right-100 {
  position: relative;
}

.bg-overlay-right-100:before {
  position: absolute;
  content: "";
  top: 0;
  right: -100%;
  background-color: #000;
  width: 100%;
  height: 100%;
}

/* Img Holder */
.bg-holder {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

/* Background overlay */
.bg-overlay-black-10 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-10:before {
  background: rgba(28, 26, 31, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-20:before {
  background: rgba(28, 26, 31, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-30 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-30:before {
  background: rgba(28, 26, 31, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-40 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-40:before {
  background: rgba(28, 26, 31, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-50:before {
  background: rgba(28, 26, 31, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-60 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-60:before {
  background: rgba(28, 26, 31, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-70:before {
  background: rgba(28, 26, 31, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-80 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-80:before {
  background: rgba(28, 26, 31, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-black-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-black-90:before {
  background: rgba(28, 26, 31, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-10 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-10:before {
  background: rgba(255, 255, 255, 0.1);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-20:before {
  background: rgba(255, 255, 255, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-30 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-30:before {
  background: rgba(255, 255, 255, 0.3);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-40 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-40:before {
  background: rgba(255, 255, 255, 0.4);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-50:before {
  background: rgba(255, 255, 255, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-60 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-60:before {
  background: rgba(255, 255, 255, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-70:before {
  background: rgba(255, 255, 255, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-80 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-80:before {
  background: rgba(255, 255, 255, 0.8);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-90:before {
  background: rgba(255, 255, 255, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-white-97 {
  position: relative;
  z-index: 1;
}

.bg-overlay-white-97:before {
  background: rgba(255, 255, 255, 0.97);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-theme-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-theme-20:before {
  background: rgba(33, 200, 122, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-theme-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-theme-50:before {
  background: rgba(33, 200, 122, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-theme-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-theme-70:before {
  background: rgba(33, 200, 122, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-theme-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-theme-90:before {
  background: rgba(33, 200, 122, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-theme-97 {
  position: relative;
  z-index: 1;
}

.bg-overlay-theme-97:before {
  background: rgba(33, 200, 122, 0.97);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light-20 {
  position: relative;
  z-index: 1;
}

.bg-overlay-light-20:before {
  background: rgba(249, 249, 249, 0.2);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light-50 {
  position: relative;
  z-index: 1;
}

.bg-overlay-light-50:before {
  background: rgba(249, 249, 249, 0.5);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light-70 {
  position: relative;
  z-index: 1;
}

.bg-overlay-light-70:before {
  background: rgba(249, 249, 249, 0.7);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light-90 {
  position: relative;
  z-index: 1;
}

.bg-overlay-light-90:before {
  background: rgba(249, 249, 249, 0.9);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.bg-overlay-light-97 {
  position: relative;
  z-index: 1;
}

.bg-overlay-light-97:before {
  background: rgba(249, 249, 249, 0.97);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

/* Overlay */
.bg-blue-overlay:before {
  background: rgba(39, 114, 251, 0.8);
}

.bg-purple-overlay:before {
  background: rgba(154, 39, 251, 0.8);
}

.bg-green-overlay:before {
  background: rgba(19, 218, 145, 0.8);
}

.bg-gold-overlay:before {
  background: rgba(251, 174, 39, 0.8);
}

.bg-pink-overlay:before {
  background: rgba(251, 39, 159, 0.8);
}

.bg-orange-overlay:before {
  background: rgba(251, 109, 39, 0.8);
}

/* Avatar */
.avatar {
  min-width: 50px;
  width: 50px;
  display: inline-block;
}

.avatar.avatar-sm {
  min-width: 20px;
  width: 20px;
}

.avatar.avatar-md {
  min-width: 80px;
  width: 80px;
}

.avatar.avatar-lg {
  min-width: 90px;
  width: 90px;
}


/* Modal Dialog */
.modal {
  background-color: rgba(0, 0, 0, 0.7);
}
.modal-dialog .login-social-media .btn {
  padding: 10px 12px;
}
/* margin */
.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.me-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ms-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.me-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ms-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.me-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ms-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.me-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ms-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.me-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ms-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -7rem !important;
}

.me-n6,
.mx-n6 {
  margin-right: -7rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -7rem !important;
}

.ms-n6,
.mx-n6 {
  margin-left: -7rem !important;
}

.m-n7 {
  margin: -9rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -9rem !important;
}

.me-n7,
.mx-n7 {
  margin-right: -9rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -9rem !important;
}

.ms-n7,
.mx-n7 {
  margin-left: -9rem !important;
}

/*****************************
  Button
*****************************/
button {
  outline: medium none !important;
  color: #000;
}

.btn-close:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Btn */
.btn {
  display: inline-block;
  font-size: 14px;
  line-height: 22px;
  padding: 13px 36px;
  font-weight: 600;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn:not(:disabled):not(.disabled).active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn:not(:disabled):not(.disabled):active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #1c1a1f;
}

.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.show>.btn.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.show>.btn-primary.dropdown-toggle {
  background: #000 !important;
  border-color: #000 !important;
}

.btn-primary {
  background: #000;
  border-color: #000;
  color: #ffffff;
}

.btn-primary:hover {
  background: #1c1a1f;
  border-color: #1c1a1f;
  color: #ffffff;
}

.btn-primary:hover:not(:disabled):not(.disabled).active {
  background: #1c1a1f;
  border-color: #1c1a1f;
}

.btn-primary:hover:not(:disabled):not(.disabled):active {
  background: #1c1a1f;
  border-color: #1c1a1f;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #1c1a1f;
  border-color: #1c1a1f;
  color: #000;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background: #000;
  border-color: #000;
}

.btn-primary.focus,
.btn-primary:focus {
  background: #000;
  border-color: #000;
}

.btn:not(:disabled):not(.disabled).active:focus {
  color: #ffffff;
}

.btn+.btn {
  margin-left: 3px;
}

.btn-dark {
  background-color: #1c1a1f;
  color: #ffffff;
  border-color: #1c1a1f;
}

.btn-dark:hover {
  background-color: #000;
  border-color: #000;
}

.btn-dark:focus {
  background-color: #000;
  border-color: #000;
}

.btn-dark:not(:disabled):not(.disabled).active {
  background-color: #000;
  border-color: #000;
}

.btn-dark:not(:disabled):not(.disabled):active {
  background-color: #000;
  border-color: #000;
}

.btn-dark.focus {
  background-color: #000;
  border-color: #000;
}

.show>.btn-dark.dropdown-toggle {
  background-color: #000;
  border-color: #000;
}

/* Link */
.btn-link {
  color: #000;
  padding: 0;
  text-decoration: none;
}

.btn-link:hover {
  color: #1c1a1f;
  text-decoration: none;
  outline: none;
}

.btn-link:focus {
  color: #1c1a1f;
  text-decoration: none;
  outline: none;
}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
  color: #1c1a1f;
}

.btn-light {
  background: #ffffff;
  border-color: #ffffff;
  color: #1c1a1f;
}

.btn-light:hover {
  background: #1c1a1f;
  border-color: #1c1a1f;
  color: #ffffff;
}

.btn-light:active {
  color: #ffffff;
  background: #1c1a1f;
  border-color: #1c1a1f;
}

.btn-light:focus {
  color: #1c1a1f;
}

.btn.btn-light:not(:disabled):not(.disabled):hover:focus,
.btn.btn-light:not(:disabled):not(.disabled):active:focus {
  color: #ffffff;
}

a.text-dark {
  color: #1c1a1f !important;
}

a.text-dark:focus,
a.text-dark:hover {
  color: #000 !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-secondary {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #1c1a1f;
}

.btn-secondary:hover {
  background-color: #000;
  border-color: #000;
}

.btn-secondary:focus {
  background-color: #000;
  border-color: #000;
}

.btn-secondary .focus {
  background-color: #000;
  border-color: #000;
}

.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #000;
  border-color: #000;
}

.btn-secondary:not(:disabled):not(.disabled):active {
  background-color: #000;
  border-color: #000;
}

.show>.btn-secondary.dropdown-toggle {
  background-color: #000;
  border-color: #000;
}

/* Button Size */
.btn.btn-sm {
  padding: 12px 22px;
  font-size: 13px;
  line-height: 18px;
}

.btn.btn-md {
  padding: 14px 26px;
  line-height: 22px;
  font-size: 14px;
}

.btn.btn-lg {
  padding: 16px 30px;
  font-size: 15px;
  line-height: 24px;
}

.btn.btn-xl {
  padding: 18px 32px;
  font-size: 16px;
  line-height: 24px;
}

/* Button Outline */
.btn-outline-primary {
  color: #ffffff;
  background: #000;
  border-color: #000;
}

.btn-outline-primary:hover {
  background: transparent;
  color: #000;
  border-color: #000;
}

.btn-outline-primary:focus {
  background: transparent;
  color: #000;
  border-color: #000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #000;
  border-color: #000;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  color: #000;
}

/* Button Outline Dark */
.btn-outline-dark {
  color: #ffffff;
  background: #1c1a1f;
  border-color: #1c1a1f;
}

.btn-outline-dark:hover {
  background: transparent;
  color: #1c1a1f;
  border-color: #1c1a1f;
}

.btn-outline-dark:focus {
  background: transparent;
  color: #1c1a1f;
  border-color: #1c1a1f;
}

.btn-outline-dark:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #1c1a1f;
  border-color: #1c1a1f;
}

/* Button Outline Dark */
.btn-outline-dark-hover {
  color: #1c1a1f;
  background: transparent;
  border-color: #1c1a1f;
}

.btn-outline-dark-hover:hover {
  background: #1c1a1f;
  color: #ffffff;
  border-color: #1c1a1f;
}

.btn-outline-dark-hover:focus {
  background: #1c1a1f;
  color: #ffffff;
  border-color: #1c1a1f;
}

.btn-outline-dark-hover:not(:disabled):not(.disabled):active {
  background: #1c1a1f;
  color: #ffffff;
  border-color: #1c1a1f;
}

/* Button Outline */
.btn-outline-primary-hover {
  color: #000;
  background: transparent;
  border-color: #000;
}

.btn-outline-primary-hover:hover {
  background: #000;
  color: #ffffff;
  border-color: #000;
}

.btn-outline-primary-hover:focus {
  background: #000;
  color: #ffffff;
  border-color: #000;
}

.btn-outline-primary-hover:not(:disabled):not(.disabled):active {
  background: transparent;
  color: #000;
  border-color: #000;
}

/* btn app */
.btn-app {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.btn-app i {
  font-size: 35px;
  padding-right: 10px;
}

.btn-app .btn-text {
  margin-top: -4px;
}

.btn-app .btn-text small {
  font-size: 10px;
  line-height: 10px;
}

.btn-app .btn-text span {
  line-height: 10px;
}

/*****************************
  Shortcodes
*****************************/
/*****************************
  Accordion
*****************************/
.card {
  border: none;
  margin-bottom: 10px;
}

.card:last-child {
  margin-bottom: 0;
}

.card-header {
  background: none;
  padding: 0;
  border-bottom: none;
}

.accordion>.card .card-header {
  margin-bottom: 0;
}

.accordion>.card .card-header .btn {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  padding: 11px 45px 11px 26px;
  font-weight: bold;
  display: inline-block;
  width: 100%;
  background: #000;
  color: #ffffff;
}

.accordion>.card .card-header .btn:after {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 12px;
  right: 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "\f068";
}

.accordion>.card .card-header .btn.collapsed {
  background: none;
  color: #1c1a1f;
}

.accordion>.card .card-header .btn.collapsed:after {
  content: "\f067";
}

.card-body {
  padding: 20px 26px;
}

/*****************************
  Shuffle
*****************************/
.filters-group {
  text-align: center;
}

.filters-group .btn-filter {
  background-color: transparent;
  color: #666666;
  padding: 9px 22px;
  border: none;
  font-weight: 500;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.filters-group .btn-filter.active {
  background-color: #000;
  color: #ffffff;
}

.my-shuffle-container {
  margin-left: -12px;
  margin-right: -12px;
}

.my-shuffle-container .grid-item {
  padding: 12px;
}

.my-shuffle-container.grid-3 .grid-item {
  width: 33.3333%;
}

.my-shuffle-container.grid-4 .grid-item {
  width: 25%;
  float: left;
}


/*****************************
  List
*****************************/
ul.list {
  padding: 0;
  margin: 0;
}

ul.list li {
  list-style: none;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

ul.list li i {
  color: #000;
  margin: 7px 5px 0px 0px;
  font-size: 10px;
}

ul.list li:last-child {
  margin-bottom: 0;
}

ul.list li a {
  color: #666666;
}

ul.list li a:hover {
  color: #000;
}

/*****************************
  Feature Info
*****************************/
/* Feature Info */
.feature-info .feature-info-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.feature-info .feature-info-icon i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 130px;
  height: 130px;
}

.feature-info .feature-info-icon img {
  position: absolute;
  right: 0px;
  top: 0px;
  -webkit-transform: translate(58%, 50%);
  transform: translate(58%, 50%);
}

/* Feature Info 02 */
.feature-info-02 [class^='col-'] {
  border-right: 1px solid rgba(249, 249, 249, 0.23);
}

.feature-info-02 [class^='col-']:last-child {
  border-right: none;
}

.feature-info-02 i {
  font-size: 50px;
}

/* Feature Info 03 */
.feature-info-03 [class^='col-'] {
  border-right: 1px solid #eaeaea;
}

.feature-info-03 [class^='col-']:last-child {
  border-right: none;
}

/* Feature Info 04 */
.feature-info-04 {
  background-color: #f9f9f9;
  padding: 40px 30px;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.feature-info-04 .feature-icon {
  color: #000;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.feature-info-04 .feature-title {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.feature-info-04:hover, .feature-info-04.black {
  background-color: #000;
  color: #ffffff;
}

.feature-info-04:hover .feature-icon, .feature-info-04.black .feature-icon {
  color: #ffffff;
}

.feature-info-04:hover .feature-title, .feature-info-04.black .feature-title {
  color: #ffffff;
}

.feature-info-04:hover .btn-link, .feature-info-04.black .btn-link {
  color: #ffffff;
}

.feature-info-04:hover .btn-link:hover, .feature-info-04.black .btn-link:hover {
  color: #1c1a1f;
}

/* Feature Step */
.feature-step .feature-step-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.feature-step .feature-step-icon i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100px;
  height: 100px;
  color: #ffffff;
}

.feature-step .feature-step-icon img {
  position: absolute;
  right: 0px;
  top: 50%;
  -webkit-transform: translate(69%, -50%);
  transform: translate(69%, -50%);
}

/*****************************
  Category
*****************************/
.categories {
  text-align: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 3px;
}

.categories:before {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(71%, rgba(0, 0, 0, 0)), to(black));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71%, black 100%);
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
}

.categories .categories-title {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 10px;
  margin-bottom: 0;
  color: #ffffff;
  z-index: 2;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.categories img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.categories:hover .categories-title {
  color: #fff;
}

.categories:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.my-shuffle-container .grid-item .categories {
  margin-bottom: 0;
}



/*****************************
  Teachers
*****************************/

.owl-carousel.teachers-shadow .owl-stage-outer {
  padding-bottom: 10px;
}

/* Tab */
.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  padding: 9px 20px;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #666666;
}

.nav-tabs .nav-link:focus {
  background: #000;
  color: #ffffff;
}

.nav-tabs .nav-link:hover {
  background: #000;
  color: #ffffff;
}

.nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background: #000;
}

.nav-tabs .nav-link.active {
  color: #ffffff;
  background: #000;
}

.nav-tabs .nav-item .nav-link {
  border: 1px solid #000
}

/*****************************
	Owl Carousel
*****************************/
.owl-carousel .owl-item img {
  width: auto;
  display: inherit;
}

.owl-carousel .owl-nav {
  cursor: pointer;
  z-index: 999;
  width: 100%;
}

.owl-carousel .owl-nav .owl-prev {
  display: block;
  position: absolute;
  text-indent: inherit;
  width: auto;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  left: 0px;
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 !important;
  display: inline-block;
  margin: 14px 2px 0;
}

.owl-carousel .owl-nav .owl-next {
  display: block;
  position: absolute;
  text-indent: inherit;
  width: auto;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  right: 0px;
  display: block;
  position: absolute;
  text-indent: inherit;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0 !important;
  display: inline-block;
  margin: 14px 2px 0;
}

.owl-carousel .owl-nav i {
  font-size: 24px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #000;
  opacity: 1;
}

.owl-carousel .owl-nav i:hover {
  color: #1c1a1f;
  opacity: 0.5;
}

.row-background .owl-carousel .owl-nav i {
  color: #ffffff;
  opacity: 0.4;
}

.row-background .owl-carousel .owl-nav i:hover {
  color: #ffffff;
  opacity: 1;
}

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-carousel button.owl-dot {
  width: 9px;
  height: 9px;
  background: #1c1a1f !important;
  border-radius: 50%;
  margin-right: 10px;
  opacity: 0.5;
}

.owl-carousel button.owl-dot.active {
  background: #000 !important;
  opacity: 1;
}

/*****************************
  Course
*****************************/
.course {
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.course:hover {
  -webkit-box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
}

.course .course-img {
  position: relative;
  overflow: hidden;
}

.course .course-img img {
  border-radius: 3px 3px 0px 0px;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.course .course-img:before {
  content: "";
  background: black;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(71%, rgba(0, 0, 0, 0)), to(black));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 71%, black 100%);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.course .course-img .course-category {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: 6px;
  left: 10px;
  right: 10px;
  z-index: 2;
}

.course .course-img .course-category:hover {
  color: #000;
}

.course .course-img .course-category i {
  color: #000;
  margin-right: 6px;
}

.course .course-info {
  padding: 20px 15px 15px;
  background: #ffffff;
  border-radius: 0px 3px 3px;
}

.course .course-info .course-title a {
  font-size: 15px;
  font-weight: 600;
  color: #1c1a1f;
}

.course .course-info .course-title a:hover {
  color: #000;
}

.course .course-info .course-instructor {
  color: #666666;
}

.course .course-info .course-rate-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 22px;
  border-top: 1px solid #eaeaea;
}

.course .course-info .course-rate-price .rating {
  font-size: 12px;
  font-weight: 600;
}

.course .course-info .course-rate-price .rating a {
  color: #666666;
}

.course .course-info .course-rate-price .rating a:hover {
  color: #000;
}

.course .course-info .course-rate-price .rating span {
  background-color: #ffc107;
  color: #ffffff;
  padding: 2px 7px;
  margin-right: 8px;
  border-radius: 3px;
}

.course .course-info .course-rate-price .price {
  font-size: 18px;
  color: #1c1a1f;
  font-weight: 600;
}

.course:hover .course-img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Course Filter */
.course-filter {
  position: relative;
  background: #ffffff;
  padding: 10px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border-radius: 3px;
}

.course-filter ul li {
  padding: 0px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.course-filter ul li a {
  color: #666666;
  font-size: 12px;
}

.course-filter ul li a:hover {
  color: #000;
}

.course-filter ul li.active a span {
  background: #000;
}

.course-filter ul:nth-child(2n+2) li:last-child {
  border-right: none;
  padding-left: 0;
  padding-right: 0px;
}

.course-filter .course-short .select2-container {
  min-width: auto;
  margin-left: 30px;
}

.course-filter .course-view-list {
  margin-left: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-filter .course-list-icon {
  display: block;
  background: rgba(33, 200, 122, 0.1);
  padding: 10px 10px 8px;
  border-radius: 3px;
}

.course-filter .course-list-icon span {
  margin: 0 auto;
  background: #000;
  height: 2px;
  width: 10px;
  border-radius: 3px;
  margin-bottom: 2px;
  display: block;
}

.course-filter .course-list-icon span:nth-child(2) {
  width: 16px;
}

.course-filter .course-list-icon:hover,
.course-filter .course-list-icon .active {
  background: #000;
}

.course-filter .course-list-icon:hover span,
.course-filter .course-list-icon .active span {
  background: #ffffff;
}

.course-filter .course-list-icon.active {
  background: #000;
}

.course-filter .course-list-icon.active span {
  background: #ffffff;
}

.course-filter .course-grid-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: rgba(33, 200, 122, 0.1);
  color: #000;
  padding: 10px 10px 8px;
  border-radius: 3px;
}

.course-filter .course-grid-icon span {
  background: #000;
  width: 2px;
  height: 10px;
  border-radius: 3px;
  margin: 0 1px;
  display: inline-block;
}

.course-filter .course-grid-icon span:nth-child(2) {
  height: 16px;
  margin-top: -3px;
}

.course-filter .course-grid-icon:hover {
  background: #000;
}

.course-filter .course-grid-icon:hover span {
  background: #ffffff;
}

.course-filter .course-grid-icon.active {
  background: #000;
}

.course-filter .course-grid-icon.active span {
  background: #ffffff;
}

.course-filter .select2-container .select2-selection--single {
  height: 40px;
  padding: 0px 15px;
}

.course-filter .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0px;
  line-height: 40px;
}

.course-filter .select2-container .select2-selection--single .select2-selection__arrow {
  height: 40px;
  right: 5px;
}

.course-filter .select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #666666 transparent transparent transparent;
}

.course-filter .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #666666 transparent;
}

/* Course list */
.course-list .course {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.course-list .course .course-img:before {
  border-radius: 0px 0px 0px 3px;
}

.course-list .course .course-img img {
  border-radius: 3px 0px 0px 3px;
}


.meta-course {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 20px;
}

.meta-course li {
  list-style: none;
  margin-right: 20px;
}

.meta-course li:last-child {
  margin-right: 0;
}

.meta-course li i {
  color: #000;
  margin-right: 10px;
}

.course-d-Teacher img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.course-details .media-img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
}

.social-share {
  position: relative;
}

.social-share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  background: #000;
  border-radius: 3px;
  padding: 1px 2px;
  margin-bottom: 0;
  right: 10px;
  top: -3px;
  position: absolute;
  visibility: hidden;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.social-share ul:before {
  position: absolute;
  z-index: 999;
  content: "";
  right: -6px;
  top: 9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #000;
}

.social-share ul li {
  line-height: 26px;
  display: inline;
  list-style-type: none;
}

.social-share ul li a {
  padding: 2px 10px 2px;
  font-size: 14px;
  color: #ffffff;
}

.social-share ul li a:hover {
  color: #1c1a1f;
}

.social-share:hover ul {
  opacity: 1;
  right: 25px;
  visibility: visible;
}

.course-details .social-share ul {
  right: auto;
  left: 50%;
  top: -44px;
  -webkit-transform: translate(-56%, 0%);
  transform: translate(-56%, 0%);
}

.course-details .social-share ul:before {
  bottom: -6px;
  top: auto;
  right: 50%;
  border-width: 6px 6px 0px 6px;
  border-color: #000 transparent transparent transparent;
  -webkit-transform: translate(4px, 0px);
  transform: translate(4px, 0px);
}

.course-details .social-share:hover ul {
  top: -34px;
}

/* property item map */
.si-content-wrapper {
  padding: 0;
}

.custom-window.open .si-frame {
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow: visible;
}

.map-canvas {
  width: 100%;
  height: 100%;
}

.custom-window {
  top: 30px;
  width: 280px;
  -webkit-transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: top 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  visibility: hidden;
}

.custom-window .si-content {
  overflow: visible;
}

.custom-window.active {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.course-item-map {
  position: relative;
  max-height: inherit;
  padding: 180px 12px 12px 12px;
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: auto;
  z-index: 2;
}

.course-item-map .course-item-map-content .course-instructor {
  color: #666666;
}

.course-item-map .course-item-map-content .course-instructor a {
  color: #000;
  margin-left: 3px;
}

.course-item-map .course-item-map-content .course-instructor a:hover {
  color: #1c1a1f;
}

.course-item-map .course-item-map-content .course-rate-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #eaeaea;
}

.course-item-map .course-item-map-content .course-rate-price .rating span {
  background-color: #ffc107;
  color: #ffffff;
  padding: 2px 7px;
  margin-right: 8px;
  border-radius: 3px;
}

.course-item-map .course-item-map-content .course-rate-price .rating a {
  color: #666666;
}

.course-item-map .course-item-map-content .course-rate-price .rating a:hover {
  color: #000;
}

.course-item-map .course-item-map-content .course-rate-price .price {
  font-size: 18px;
  color: #1c1a1f;
  font-weight: 600;
}

.si-pointer-bg-top {
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.course-item-map-img {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
}

.course-item-map-title {
  margin-top: 12px;
  line-height: 22px;
}

.course-item-map-price {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.course-item-map-content p {
  margin: 0;
}

.course-item-map-content *+p {
  margin-top: 1em;
}

.course-item-map-content a {
  color: #666666;
}

.course-item-map-content a:hover {
  color: #000;
}

.course-item-map-content a:focus {
  color: #000;
}

.course-item-map-content a:active {
  color: #000;
}

.custom-close {
  position: absolute;
  top: -8px;
  right: -8px;
  z-index: 9;
  width: 24px;
  padding: 0 8px;
  height: 24px;
  border-radius: 3px;
  -webkit-transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  border: 0;
  background-color: #000;
  color: #ffffff;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.custom-close:hover {
  background-color: #000;
}

.custom-close:focus {
  background-color: #000;
}

.custom-close:active {
  background-color: #000;
}

.course-map .map-canvas {
  height: 500px;
}


/*****************************
	Progress Bar
*****************************/
/* Progress Bar Style 1 */
.progress-style-1 .progress-item {
  margin-bottom: 20px;
}

.progress-style-1 .progress-item:last-child {
  margin-bottom: 0px;
}

.progress-style-1 .progress-item .progress-title {
  color: #1c1a1f;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
}

.progress-style-1 .progress-item .progress {
  overflow: inherit;
  background-color: #eaeaea;
  border-radius: 3px;
  position: relative;
}

.progress-style-1 .progress-item .progress span {
  color: #1c1a1f;
  font-weight: 700;
  font-size: 14px;
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: 15px;
}

.progress-style-1 .progress-item .progress .progress-bar {
  overflow: inherit;
  background-color: #000;
  border-radius: 3px;
}

/*****************************
  Pricing Table
*****************************/
.pricing {
  padding: 30px 20px;
  text-align: center;
  border-radius: 3px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
}

.pricing .pricing-title {
  margin-bottom: 20px;
}

.pricing .pricing-price {
  display: inline-block;
  position: relative;
  color: #666666;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
}

.pricing .pricing-price sup {
  position: absolute;
  top: 15px;
  left: -16px;
  font-size: 24px;
  color: #1c1a1f;
  font-weight: 600;
}

.pricing .pricing-price strong {
  color: #1c1a1f;
  font-size: 50px;
  line-height: 50px;
  font-weight: 600;
}

.pricing .pricing-list {
  margin-top: 20px;
  margin-bottom: 30px;
}

.pricing .pricing-list li {
  padding: 10px 0;
  color: #1c1a1f;
}

.pricing .pricing-list li i {
  margin-right: 6px;
  font-size: 12px;
}

.pricing .pricing-list li:last-child {
  padding-bottom: 0;
}

.pricing.active {
  background: #000;
  color: #ffffff;
}

.pricing.active .pricing-list li {
  color: #ffffff;
}

.pricing.active .pricing-title {
  color: #ffffff;
}

.pricing.active .pricing-price {
  color: #ffffff;
}

.pricing.active sup {
  color: #ffffff;
}

.pricing.active strong {
  color: #ffffff;
}

/*****************************
  Event
*****************************/
.event {
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border-radius: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.event:hover {
  -webkit-box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
}

.event .event-img {
  position: relative;
}

.event .event-img img {
  border-radius: 3px 3px 0px 0px;
}

.event .event-img .event-date {
  background-color: #000;
  color: #ffffff;
  width: 70px;
  font-size: 20px;
  font-weight: 700;
  padding-top: 5px;
  text-align: center;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 3px;
}

.event .event-img .event-date small {
  display: block;
  padding-bottom: 3px;
  text-transform: uppercase;
}

.event .event-img .event-date .years {
  display: block;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 0px;
  background-color: #666666;
  border-radius: 0px 0px 3px 3px;
}

.event .event-info {
  padding: 20px;
  background: #ffffff;
}

.event .event-info .meta-event {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0px;
  margin-bottom: 10px;
}

.event .event-info .meta-event li {
  list-style: none;
  margin-right: 15px;
}

.event .event-info .meta-event li:last-child {
  margin-right: 0;
}

.event .event-info .meta-event li i {
  color: #000;
  margin-right: 10px;
}

.event .event-info .event-title {
  margin-bottom: 0px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event .event-info .event-title:hover a {
  color: #000;
}

/*****************************
	Range Slider
*****************************/
.irs--flat .irs-line {
  height: 6px;
}

.irs--flat .irs-bar {
  background: #000;
  height: 6px;
}

.irs--flat .irs-from {
  background: transparent;
  color: #000;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-from:before {
  display: none;
}

.irs--flat .irs-to {
  background: transparent;
  color: #000;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-to:before {
  display: none;
}

.irs--flat .irs-single {
  background: transparent;
  color: #000;
  font-size: 13px;
  font-weight: 600;
}

.irs--flat .irs-single:before {
  display: none;
}

.irs--flat .irs-handle>i:first-child {
  background: #ffffff;
  background: #ffffff;
  width: 12px;
  width: 12px;
  height: 12px;
  height: 12px;
  border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #000;
  border: 2px solid #000;
  cursor: pointer;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.irs--flat .irs-handle:hover>i:first-child {
  background: #000;
}

.irs--flat .irs-handle.state_hover>i:first-child {
  background: #000;
}

/*****************************
  Datetimepicker
*****************************/
.bootstrap-datetimepicker-widget table td.active {
  background-color: #000;
}

.bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #000;
}

.bootstrap-datetimepicker-widget table td.day {
  height: 30px;
  line-height: 30px;
  width: 40px;
  font-size: 14px;
}

.bootstrap-datetimepicker-widget table th {
  font-size: 14px;
}

.bootstrap-datetimepicker-widget table td span.active {
  background: #000;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 19rem;
}

.input-group.date .input-group-text {
  background-color: #f9f9f9;
  border-color: #eaeaea;
  height: 100%;
}

/*****************************
  Header
*****************************/
/* Header */
.header .navbar {
  padding: 30px 0px;
}

.header .navbar .navbar-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .navbar .navbar-nav li>a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 3px 0px;
  color: #666666;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

.header .navbar .navbar-nav li>a:hover {
  color: #000;
}

.header .navbar .navbar-nav li>a i {
  margin-left: 5px;
  font-size: 10px;
}

.header .navbar .navbar-nav .nav-link {
  font-weight: 500;
  font-size: 14px;
  padding: 12px 8px 12px 0;
  color: #666666;
  text-transform: capitalize;
  position: relative;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

.header .navbar .navbar-nav .nav-link i {
  font-weight: bold;
}

.header .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

.header .navbar .nav-item {
  margin-right: 20px;
}

.header .navbar .nav-item:last-child {
  margin-right: 0px;
}

.header .navbar .nav-item.megamenu-nav-area {
  position: inherit;
}

.header .navbar .nav-item.active .nav-link {
  color: #000;
}

.header .navbar-brand {
  padding: 0px;
  margin-right: 0;
  color: #ffffff;
  text-align: left;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 190px;
  flex: 0 0 190px;
  -webkit-transition: none;
  transition: none;
}

.header .navbar-brand img {
  height: 48px;
}

.header.default-transparent {
  position: absolute;
  width: 100%;
  z-index: 9;
}

.header.default-transparent .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

.header.default-transparent .navbar .nav-item.active .nav-link {
  color: #000;
  font-weight: 700;
}

.header-position {
  padding-top: 108px;
}

.header .navbar .navbar-nav li.dropdown-submenu a {
  padding: 8px 20px;
}

.header .navbar .navbar-nav li.dropdown-submenu a i {
  margin-right: 0px;
}

/* Header Transparent 02 */
.header.default-transparent-02 .navbar .nav-item.active .nav-link {
  color: #1c1a1f;
}

.header.default-transparent-02 .navbar .navbar-nav .nav-link:hover {
  color: #1c1a1f;
}

.header.default-transparent-02.is-sticky .navbar .nav-item.active .nav-link {
  color: #000;
}

.header.default-transparent-02.is-sticky .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

/* Header Transparent 03 */
.header.default-transparent.default-transparent-03 .navbar-brand-sticky {
  display: none;
}

.header.default-transparent.default-transparent-03 .navbar .navbar-nav .nav-link {
  color: #1c1a1f;
}

.header.default-transparent.default-transparent-03 .navbar .nav-item.active .nav-link {
  color: #000;
}

.header.default-transparent.default-transparent-03 .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

.header.default-transparent.default-transparent-03 .search-category {
  border: none;
}

.header.default-transparent.default-transparent-03 .search-category button {
  background: #000;
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  padding: 14px 25px 14px 15px;
  right: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: #ffffff;
  border-radius: 0px 50px 50px 0px;
}

.header.default-transparent.default-transparent-03 .search-category .form-control {
  width: 260px;
  height: 50px;
  padding: 14px 70px 14px 20px;
  background: #ffffff;
  color: #666666;
}

.header.default-transparent.default-transparent-03 .search-category .form-control::-webkit-input-placeholder {
  color: #666666;
}

.header.default-transparent.default-transparent-03 .search-category .form-control::-moz-placeholder {
  color: #666666;
}

.header.default-transparent.default-transparent-03 .search-category .form-control:-ms-input-placeholder {
  color: #666666;
}

.header.default-transparent.default-transparent-03 .search-category .form-control::-ms-input-placeholder {
  color: #666666;
}

.header.default-transparent.default-transparent-03 .search-category .form-control::placeholder {
  color: #666666;
}

.header.default-transparent.default-transparent-03 .woo-action ul li>a {
  color: #1c1a1f;
}

.header.default-transparent.default-transparent-03 .woo-action ul li>a i {
  color: #000;
}

.header.default-transparent-03.is-sticky .navbar-brand {
  display: none;
}

.header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-link {
  color: #ffffff;
}

.header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

.header.default-transparent-03.is-sticky .navbar .navbar-nav .nav-item.active .nav-link {
  color: #000;
}

.header.default-transparent-03.is-sticky .woo-action ul li>a {
  color: #ffffff;
}

.header.default-transparent-03.is-sticky .woo-action ul li>a:hover {
  color: #000;
}

/* Header Sticky */
.header.is-sticky {
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;
  background-color: #1c1a1f;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  padding: 0px;
}

.header.is-sticky .topbar {
  display: none;
}

.header.is-sticky .navbar {
  padding: 25px 0px;
}

.header.is-sticky .navbar .navbar-brand-sticky {
  display: block;
}

/* Mega Menu */
.megamenu {
  padding: 20px 20px;
  width: 100%;
  border-radius: 0px;
}

.megamenu>div>li>ul {
  padding: 0;
  margin: 0;
}

.megamenu>div>li>ul>li {
  list-style: none;
}

.megamenu>div>li>ul>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #1c1a1f;
  white-space: normal;
}

.megamenu>div>li>ul>li>a:hover {
  text-decoration: none;
  color: #1c1a1f;
  background-color: #f9f9f9;
}

.megamenu>div>li>ul>li>a:focus {
  text-decoration: none;
  color: #1c1a1f;
  background-color: #f9f9f9;
}

.megamenu.disabled>a:hover {
  color: #f9f9f9;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.megamenu.disabled>a:focus {
  color: #f9f9f9;
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: not-allowed;
}

.header .megamenu .dropdown-item {
  padding: 0px;
  font-weight: 500;
}

.header li>.dropdown-item:focus {
  color: #000;
  background: none;
}

.header li>.dropdown-item:hover {
  color: #000;
  background: none;
}

.header .dropdown-item.active {
  background: none;
}

.header .dropdown-item:active {
  background: none;
}

.header .dropdown-item:focus {
  background: none;
}

.header .dropdown-item:hover {
  background: none;
}

.header .dropdown-toggle::after {
  content: none;
}

.header .navbar-collapse {
  -webkit-box-align: inherit;
  -ms-flex-align: inherit;
  align-items: inherit;
}

.header .navbar .dropdown-menu {
  padding: 0px 0px 15px 0px;
  z-index: 9999;
  border: none;
}

.header .navbar .dropdown-menu a.dropdown-item {
  min-width: 180px;
}

.header .navbar .dropdown-menu li a i {
  margin-left: auto;
  margin-right: 20px;
}

.navbar .dropdown>.dropdown-menu li>a {
  font-size: 13px;
  padding: 0px;
  color: #666666;
  position: relative;
  letter-spacing: 1px;
}

.navbar .dropdown>.dropdown-menu li>a:hover {
  color: #000;
}

.navbar .dropdown>.dropdown-menu li>a span {
  padding: 8px 20px;
}

.navbar .dropdown>.dropdown-menu li:last-child {
  border-bottom: none;
}

.navbar .dropdown>.dropdown-menu li:last-child>a span:before {
  content: none;
}

.navbar .dropdown>.dropdown-menu .nav-title {
  color: #1c1a1f;
  padding: 8px 20px 0px;
}

.navbar .navbar-nav .dropdown-menu li.active>a span {
  color: #000;
}

.navbar .navbar-nav .dropdown-menu li.active>a span:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.header .woo-action>ul {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header .woo-action>ul li {
  display: inline-block;
  margin-right: 20px;
}

.header .woo-action>ul li:last-child {
  margin-right: 0px;
}

.header .woo-action>ul li a {
  color: #666666;
  font-size: 14px;
  font-weight: 700;
  position: relative;
}

.header .woo-action>ul li a i {
  font-size: 16px;
  color: #000;
}

.header .woo-action>ul li a:hover {
  color: #000;
}


.header.default-transparent .woo-action ul li>a:hover {
  color: #fff;
}

.header.default-transparent .woo-action ul li>a i {
  color: #ffffff;
}

/* Search Category */
.header .search-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  margin-right: 30px;
  border: 2px solid rgba(172, 154, 154, 0.2);
  border-radius: 50px;
}

.header .search-category .form-control {
  font-size: 14px;
  line-height: 1;
  color: #666666;
  padding: 14px 65px 14px 20px;
  width: 340px;
  height: 44px;
  background: transparent;
  border: none;
}

.header .search-category .form-control::-webkit-input-placeholder {
  color: #666666;
}

.header .search-category .form-control::-moz-placeholder {
  color: #666666;
}

.header .search-category .form-control:-ms-input-placeholder {
  color: #666666;
}

.header .search-category .form-control::-ms-input-placeholder {
  color: #666666;
}

.header .search-category .form-control::placeholder {
  color: #666666;
}

.header .search-category .search-button {
  background: #000;
  border: none;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  padding: 12px 25px 12px 15px;
  right: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  color: #ffffff;
  border-radius: 0px 50px 50px 0px;
}

.header .search-category .search-button:hover {
  color: #1c1a1f;
}

.header .search-category .course-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  padding: 10px 20px;
  margin-bottom: 0;
  border: none;
  cursor: pointer;
  border-right: 2px solid rgba(172, 154, 154, 0.2);
}

.header .search-category .course-category .select2-container--default .select2-selection--single {
  background: transparent;
  color: #ffffff;
  border: none;
  height: inherit;
}

.header .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #666666;
  padding: 0;
  font-weight: 600;
  line-height: inherit;
}

.header .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 8px;
  height: 24px;
  top: 0;
  right: 0;
}

.select2-container {
  border-radius: 50px;
}

.select2-container span {
  outline: 0;
}

.select2-dropdown {
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  border: none;
  border-radius: 3px;
}

.select2-results__option {
  padding: 8px 15px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #000;
}

.select2-container--default .select2-search--dropdown {
  padding: 8px 15px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #cccccc;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f9f9f9;
  color: #000;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #cccccc transparent transparent transparent;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #cccccc transparent;
}

.select2-container--open .select2-dropdown--below {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.select2-search--dropdown .select2-search__field {
  border-radius: 3px;
}

.header.default-transparent .search-category {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.header.default-transparent .search-category .form-control {
  color: #ffffff;
}

.header.default-transparent .search-category .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.header.default-transparent .search-category .form-control::-moz-placeholder {
  color: #ffffff;
}

.header.default-transparent .search-category .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.header.default-transparent .search-category .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.header.default-transparent .search-category .form-control::placeholder {
  color: #ffffff;
}

.header.default-transparent .search-category .course-category {
  border-right: 2px solid rgba(255, 255, 255, 0.5);
}

.header.default-transparent .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #ffffff;
}

.header.is-sticky .navbar .navbar-nav .nav-link {
  color: #ffffff;
}

.header.is-sticky .navbar .navbar-nav .nav-link:hover {
  color: #000;
}

.header.is-sticky .navbar .nav-item.active .nav-link {
  color: #000;
}

.header.is-sticky .search-category {
  border-color: rgba(255, 255, 255, 0.5);
}

.header.is-sticky .search-category .form-control {
  color: #ffffff;
}

.header.is-sticky .search-category .form-control::-webkit-input-placeholder {
  color: #ffffff;
}

.header.is-sticky .search-category .form-control::-moz-placeholder {
  color: #ffffff;
}

.header.is-sticky .search-category .form-control:-ms-input-placeholder {
  color: #ffffff;
}

.header.is-sticky .search-category .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.header.is-sticky .search-category .form-control::placeholder {
  color: #ffffff;
}

.header.is-sticky .search-category .course-category {
  border-right-color: rgba(255, 255, 255, 0.5);
}

.header.is-sticky .search-category .course-category .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #ffffff;
}

.header.is-sticky .woo-action ul li>a {
  color: #ffffff;
}

.header.is-sticky .woo-action ul li>a:hover {
  color: #000;
}

/*****************************
  Banner
*****************************/
/* Banner 01 */
/* slide 01 */
.slider-01:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 117px;
  background: black;
  background: -webkit-gradient(linear, left top, left bottom, from(#1c1a1f), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(180deg, #1c1a1f 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
}

.slider-01 .swiper-slide {
  height: 840px;
}

/* Banner 02 */
.banner-04 {
  height: 803px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner-04 .content h2 {
  font-size: 54px;
  line-height: 60px;
}

.banner-04 .banner-shep {
  position: absolute;
  top: 0;
  right: 0;
}

.banner-04 figure {
  position: absolute;
  top: -2px;
  right: 0px;
  height: 100%;
  width: 1109px;
}

.banner-04 .form-inline .form-control {
  padding-right: 98px;
}

.banner-04 .form-inline .form-control:focus {
  border-color: #1c1a1f;
}

.banner-04 .form-inline .btn-dark:hover {
  background-color: #1c1a1f;
  border-color: #1c1a1f;
}

/* Bullet */
.swiper-pagination .swiper-pagination-bullet {
  background-color: #cccccc;
  width: 88px;
  height: 5px;
  border-radius: 0;
  opacity: 1;
  outline: none;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #000;
}

.swiper-pagination.swiper-pagination-left {
  text-align: left;
  margin: 0 auto;
  right: 0;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Arrow */
.swiper-button-next {
  right: 53px;
  background-image: inherit !important;
  outline: none;
  width: 50px;
  height: 50px;
}

.swiper-button-next i {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(172, 154, 154, 0.2);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  outline: none;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.swiper-button-next:hover i {
  color: #ffffff;
  background-color: rgba(172, 154, 154, 0.5);
}

.swiper-button-prev {
  left: 53px;
  background-image: inherit !important;
  outline: none;
  width: 50px;
  height: 50px;
}

.swiper-button-prev i {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(172, 154, 154, 0.2);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  outline: none;
  border-radius: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.swiper-button-prev:hover i {
  color: #ffffff;
  background-color: rgba(172, 154, 154, 0.5);
}


/*****************************
  Layout
*****************************/
/* Section Title */
.section-title {
  margin-bottom: 30px;
}

.section-title h2 {
  color: #1c1a1f;
  margin-bottom: 10px;
}

/* Client Logo */
.our-clients .owl-item {
  text-align: center;
}

.our-clients .owl-item img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.our-clients .owl-item:hover img {
  -webkit-filter: inherit;
  filter: inherit;
}

/* Back To Top */
.back-to-top a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 40px;
  width: 40px;
  height: 40px;
  margin: 0px;
  color: #ffffff;
  font-size: 16px;
  background: #000;
  border-radius: 50px;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  box-shadow: 0px 6px 10px 0px rgba(28, 26, 31, 0.08);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 999;
}

.back-to-top a:hover {
  background: #1c1a1f;
  color: #ffffff;
}

.back-to-top a:focus {
  background: #1c1a1f;
  color: #ffffff;
}

/* Social-icon */
.social-icon ul {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.social-icon ul li {
  display: inline-block;
  margin: 0px 15px;
}

.social-icon ul li:first-child {
  margin-left: 0;
}

.social-icon ul li:last-child {
  margin-right: 0;
}

.social-icon ul li a {
  color: #1c1a1f;
  font-size: 14px;
}

.social-icon ul li a:hover {
  color: #000;
}

/* Social-icon */
.social-icon-round ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.social-icon-round ul li {
  display: inline-block;
  margin: 0px 8px;
}

.social-icon-round ul li:first-child {
  margin-left: 0;
}

.social-icon-round ul li:last-child {
  margin-right: 0;
}

.social-icon-round ul li a {
  display: block;
  color: #000;
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 50px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.social-icon-round ul li a:hover {
  color: #ffffff;
  background: #000;
}

/* Social-icon-small */
.social-icon-round.icon-sm ul li a {
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

/* Social Bg Color */
.social-bg-hover {
  position: relative;
  color: #ffffff;
}

.social-bg-hover:before {
  content: "";
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.social-bg-hover:hover {
  color: #ffffff;
}

.social-bg-hover:hover:before {
  background-color: rgba(0, 0, 0, 0.1);
}

.social-bg-hover span {
  position: relative;
}

.facebook-bg {
  background-color: #445c8e;
}

.twitter-bg {
  background-color: #43afe9;
}

.google-bg {
  background-color: #dc0510;
}

.instagram-bg {
  background-color: #DD2A7B;
}

.linkedin-bg {
  background-color: #007eb3;
}

.pinterest-bg {
  background-color: #E60023;
}

.bg-blue {
  background-color: #2772fb;
}

.bg-purple {
  background-color: #9a27fb;
}

.bg-green {
  background-color: #13da91;
}

.bg-gold {
  background-color: #fbae27;
}

.bg-pink {
  background-color: #fb279f;
}

.bg-orange {
  background-color: #fb6d27;
}

/* Maintenance */
.maintenance-content {
  padding-top: 100px;
}

/* Coming soon */
.coming-soon-top {
  position: absolute;
  left: 0;
  right: 0;
}

.coming-soon-section {
  padding-top: 100px;
}

.coming-soon-section .coming-soon-icon i {
  font-size: 110px;
  color: #cccccc;
}

/* Form */
.form-control {
  padding: 14px 20px;
  height: 50px;
  font-size: 14px;
  border-color: #eaeaea;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #666666;
  border-radius: 50px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #000;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

textarea.form-control {
  height: auto;
}

.custom-control-input:checked~.custom-control-label:before {
  background: #000;
  border-color: #000;
}

.custom-control-input:focus~.custom-control-label::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #000;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: #000;
  border-color: #000;
}

/* Form Fill */
.fill-form .form-control {
  background-color: #f9f9f9;
}

/* Table */
.table-striped thead tr {
  background-color: #000;
  color: #ffffff;
  font-size: 16px;
}

.table-striped thead tr th {
  font-weight: normal;
  border: none;
}

.table-striped tbody tr td {
  color: #666666;
  border-top-color: #eaeaea;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

/* Search Course */
.search-course .form-control {
  padding-right: 130px;
  border-color: #ffffff;
}

.search-course .form-control:focus {
  border-color: #000;
}

/* Select */
.select2-container .select2-selection--single {
  height: 50px;
  border-radius: 3px;
  border-color: #eaeaea;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  color: #666666;
  line-height: 50px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0px 30px;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  height: 50px;
  right: 20px;
  width: 20px;
}


/* Input Size */
.form-control-sm {
  height: 44px;
}

.form-control-md {
  height: 52px;
}

.form-control-lg {
  height: 58px;
}

.form-control-xl {
  height: 62px;
  font-size: 1.45rem;
}

/* Form Inline */
.form-inline {
  position: relative;
}

.form-inline .btn {
  position: absolute;
  top: 0;
  right: 0;
}

.form-inline .form-group {
  width: 100%;
}

.form-inline .form-control {
  width: 100%;
}

.form-flat-style .form-control {
  background-color: #f9f9f9;
  border-color: #eaeaea;
  border-radius: 3px;
}

.form-flat-style .form-control:focus {
  border-color: #000;
}

/* form ratings */
.ratings {
  border: none;
  float: left;
}

.ratings>input {
  display: none;
}

.ratings>label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  content: "\f005";
  font-weight: 900;
}

.ratings>.half:before {
  content: "\f089";
  position: absolute;
}

.ratings>label {
  color: #ddd;
  float: right;
}

.ratings>input:checked~label,
.ratings:not(:checked)>label:hover,
.ratings:not(:checked)>label:hover~label {
  color: #FFD700;
}

.ratings>input:checked+label:hover,
.ratings>input:checked~label:hover,
.ratings>label:hover~input:checked~label,
.ratings>input:checked~label:hover~label {
  color: #FFED85;
}

/* Newsletter */
.input-with-btn .form-control {
  padding-right: 124px;
}

/* Newsletter Input Size */
.input-with-btn .form-control-sm {
  padding-right: 114px;
}

.input-with-btn .form-control-md {
  padding-right: 124px;
}

.input-with-btn .form-control-lg {
  padding-right: 146px;
}

.input-with-btn .form-control-xl {
  padding-right: 156px;
}

/* Zoom Effect */
.img-zoom {
  overflow: hidden;
  width: 202px;
}

.img-zoom img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 202px;
}

.img-zoom img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Navigation */
.navigation .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navigation .nav-links .nav-previous {
  width: 47%;
  border: 1px solid #eaeaea;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 50px;
}

.navigation .nav-links .nav-previous a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #666666;
}

.navigation .nav-links .nav-previous .pagi-text {
  padding: 12px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation .nav-links .nav-previous:hover {
  background: #f9f9f9;
}

.navigation .nav-links .nav-previous:hover .pagi-text {
  background: #000;
  color: #ffffff;
}

.navigation .nav-links .nav-previous:hover .nav-title {
  color: #000;
}

.navigation .nav-links .nav-previous .nav-title {
  padding: 12px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.navigation .nav-links .nav-previous .pagi-text {
  border-right: 1px solid #eaeaea;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 50px;
}

.navigation .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navigation .nav-links .nav-next {
  width: 47%;
  border: 1px solid #eaeaea;
  text-align: right;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-radius: 50px;
}

.navigation .nav-links .nav-next a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #666666;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.navigation .nav-links .nav-next .pagi-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 25px;
  border-left: 1px solid #eaeaea;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 50px;
}

.navigation .nav-links .nav-next:hover {
  background: #f9f9f9;
}

.navigation .nav-links .nav-next:hover .pagi-text {
  background: #000;
  color: #ffffff;
  border-radius: 50px;
}

.navigation .nav-links .nav-next:hover .nav-title {
  color: #000;
}

.navigation .nav-links .nav-next .nav-title {
  padding: 12px 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Pagination */
.pagination .page-item {
  margin: 3px 6px;
  position: relative;
  z-index: 1;
}

.pagination .page-item .page-link {
  display: inline-block;
  border: 1px solid #eaeaea;
  padding: 0 10px;
  font-size: 15px;
  color: #666666;
  min-width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  border-radius: 50px;
}

.pagination .page-item .page-link:hover {
  background: transparent;
  color: #000;
  border-color: #000;
}

.pagination .page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.pagination .page-item.active .page-link {
  background: transparent;
  color: #000;
  border-color: #000;
}

/*****************************
  Sidebar
*****************************/
/* Sidebar */
.widgets .widget {
  margin-bottom: 50px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(28, 26, 31, 0.1);
}

.widgets .widget:last-child {
  margin-bottom: 0px;
}

.widgets .widget-title {
  background-color: #f9f9f9;
  padding: 10px 20px;
  margin-bottom: 0;
}

.widgets .widget-content {
  padding: 30px 20px;
  border-top: 1px solid #eaeaea;
}

.widget-tag ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.widget-tag ul li {
  margin: 0px 15px 15px 0px;
}

.widget-tag ul li a {
  display: inline-block;
  padding: 8px 25px;
  border: 1px solid #eaeaea;
  border-radius: 50px;
}

.widget-tag ul li a:hover {
  border-color: #000;
}

.widget-post ul {
  padding-left: 0px;
  margin-bottom: 0px;
}

.widget-post ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
  margin-left: 0;
}

.widget-post ul li:last-child {
  margin-bottom: 0;
}

.widget-post ul li img {
  width: 60px;
  margin-right: 15px;
  border-radius: 3px;
}

.widget-course-info ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #1c1a1f;
  min-width: 120px;
}

.widget-post .widget-post-title a {
  color: #666666;
}

.widget-post .widget-post-title a:hover {
  color: #000;
}


/*****************************
  Shop
*****************************/
/* Product */
.product .product-image {
  position: relative;
}

.product .product-image .product-overlay {
  opacity: 0;
  text-align: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 1;
  margin: 0 auto;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.product:hover .product-image .product-overlay {
  opacity: 1;
}

.product .product-description {
  text-align: center;
}

.product .product-description .product-title {
  margin: 20px 0px 10px;
}

.product .product-description .product-title a {
  font-size: 16px;
  font-weight: 700;
  color: #1c1a1f;
}

.product .product-description .product-title a:hover {
  color: #000;
}

.product .product-description .product-rating {
  margin-bottom: 10px;
}

.product .product-description .product-rating i {
  color: #ffb100;
}

.product .product-description .product-price {
  font-size: 14px;
  font-weight: 700;
}

.product .product-description .product-price del {
  color: #cccccc;
}

.product .product-description .product-price ins {
  color: #000;
  text-decoration: none;
}

/* Product Detail */
.shop-single .nav-item .nav-link {
  font-size: 16px;
}

.shop-single .product-detail .product-price-rating .product-price {
  margin-bottom: 20px;
}

.shop-single .product-detail .product-price-rating .product-price h4 {
  font-size: 20px;
  margin-bottom: 0;
}

.shop-single .product-detail .product-price-rating .product-price h4 span {
  font-size: 24px;
  color: #000;
  margin-left: 8px;
}

.shop-single .product-detail .product-price-rating .product-rating {
  margin: 20px 0;
}

.shop-single .product-detail .product-price-rating .product-rating i {
  color: #000;
}

.shop-single .product-detail .product-summary {
  margin-top: 30px;
}

.shop-single .product-detail .product-summary a {
  color: #000;
  margin-right: 10px;
}

.shop-single .product-detail .product-summary a i {
  color: #000;
  margin-right: 10px;
}

.shop-single .product-detail .product-summary a:hover {
  color: #000;
}

.shop-single .product-detail hr {
  margin: 30px 0;
}

.shop-single .product-detail .product-detail-meta {
  margin-bottom: 20px;
}

.shop-single .product-detail .product-detail-meta span {
  display: block;
  margin: 10px 0;
}

.shop-single .product-detail .product-detail-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}

.shop-single .product-detail .product-detail-social span {
  font-weight: 700;
}

.shop-single .product-detail .product-detail-social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 15px;
  margin-bottom: 0;
}

.shop-single .product-detail .product-detail-social ul li {
  list-style-type: none;
  margin-right: 20px;
  font-size: 16px;
}

.shop-single .product-detail .input-group {
  width: 90px;
  float: left;
  margin-right: 20px;
}

.form-group .product-rating {
  color: #000;
}

.slider-slick .slider-nav .slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 14px;
}

.slider-slick .slider-nav .slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  z-index: 999;
  color: #ffffff;
  font-size: 14px;
}

.slider-slick .slider-nav:hover .slick-prev {
  left: 0;
  z-index: 99;
  opacity: 1;
}

.slider-slick .slider-nav:hover .slick-next {
  right: 0;
  opacity: 1;
}

.slider-slick .slider-nav .slick-prev {
  opacity: 0;
  width: 40px;
  height: 40px;
  background: #000;
  color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 3px;
}

.slider-slick .slider-nav .slick-prev:hover {
  background: #1c1a1f;
}

.slider-slick .slider-nav .slick-next {
  opacity: 0;
  width: 40px;
  height: 40px;
  background: #000;
  color: #000;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 3px;
}

.slider-slick .slider-nav .slick-next:hover {
  background: #1c1a1f;
}

.slider-slick .slider-nav .slick-track .slick-slide {
  margin-left: 5px;
  margin-right: 5px;
}

/* Comment List */
.commentlist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.commentlist .comment-content {
  border: 1px solid #eaeaea;
  padding: 15px;
  margin-left: 20px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 3px;
}

.commentlist .comment-content .reviews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.commentlist .comment-content .reviews .rating {
  margin-left: auto;
}

.commentlist .comment-content .reviews .rating i {
  color: #000;
}

.commentlist .comment-author img {
  width: 60px;
}

.form-check-label {
  padding-left: 20px;
}

/*****************************
    Footer
*****************************/
.footer .footer-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer .footer-link ul {
  padding-right: 50px;
}

.footer .footer-link ul:last-child {
  padding-right: 0px;
}

.footer .footer-link ul li a {
  color: #666666;
  padding: 12px 0px;
  display: block;
  font-size: 12px;
  line-height: 12px;
}

.footer .footer-link ul li:hover a {
  color: #000;
}

.footer .footer-link ul li:first-child a {
  padding-top: 0;
}

.footer .footer-link ul li:last-child a {
  padding-bottom: 0;
}

.footer .footer-contact-info .contact-address .contact-item a {
  color: #666666;
}

.footer .footer-title {
  margin-bottom: 30px;
  color: #1c1a1f;
}

.footer .social-icon a {
  color: #666666;
}

.footer .footer-bottom {
  padding: 30px 0px;
  background-color: #262626;
  border-top: 1px solid #eaeaea;
}

/*************************
       Responsive
*************************/

/* new css */
.statistics i {
  font-size: 50px;
}

.slick-dots {
  bottom: -40px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.fz50 {
  font-size: 50px;
}

.light-bg {
  background-color: rgb(249, 249, 249);
}

/* custom css */
.password-field button.eye-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: transparent;
  border: none;
}
.bg-primary * {
  color: #fff;
}
.slide-item * {
  color: #000;
}
.pb-80 {
  padding-bottom: 80px;
}

.bg-f9 {
   background-color: #f9f9f9;
}