.image-wrapper:before {
    background: rgba(28, 26, 31, 0.5);
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
}
.image-wrapper{
    position: relative;
}
.carousel-control-prev, .carousel-control-next {
    z-index: 3;
}

.banner-content {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
}
.banner-content h1{
    color: #fff;
    position: relative;
}
.carousel-item img{
    object-fit: cover;
}
.image-wrapper, .carousel-item, .carousel-item img, .image-wrapper:before  {
    height:840px;
}
.home-banner:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 117px;
    background: black;
    background: -webkit-gradient(linear, left top, left bottom, from(#1c1a1f), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, #1c1a1f 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 2;
}