	/*
  	Flaticon icon font: Flaticon
  	Creation date: 27/08/2020 07:16
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),;
  font-weight: normal;
  font-style: normal;
}

/* @media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
} */

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-verify:before { content: "\f100"; }
.flaticon-block:before { content: "\f101"; }
.flaticon-mail:before { content: "\f102"; }
.flaticon-shopping-cart:before { content: "\f103"; }
.flaticon-online-learning:before { content: "\f104"; }
.flaticon-skills:before { content: "\f105"; }
.flaticon-online:before { content: "\f106"; }
.flaticon-guarantee:before { content: "\f107"; }
.flaticon-customer-service:before { content: "\f108"; }
.flaticon-graduation-cap:before { content: "\f109"; }
.flaticon-stopwatch:before { content: "\f10a"; }
.flaticon-studying:before { content: "\f10b"; }
.flaticon-global:before { content: "\f10c"; }
.flaticon-user:before { content: "\f10d"; }
.flaticon-clock:before { content: "\f10e"; }
.flaticon-speech-bubble:before { content: "\f10f"; }
.flaticon-calendar:before { content: "\f110"; }
.flaticon-money:before { content: "\f111"; }
.flaticon-entrepreneur:before { content: "\f112"; }
.flaticon-chat:before { content: "\f113"; }
.flaticon-idea:before { content: "\f114"; }
.flaticon-sales:before { content: "\f115"; }
.flaticon-operation:before { content: "\f116"; }
.flaticon-checklist:before { content: "\f117"; }
.flaticon-auction:before { content: "\f118"; }
.flaticon-statistics:before { content: "\f119"; }
.flaticon-building:before { content: "\f11a"; }
.flaticon-online-learning-1:before { content: "\f11b"; }
.flaticon-strategy:before { content: "\f11c"; }
.flaticon-lock:before { content: "\f11d"; }
.flaticon-apple:before { content: "\f11e"; }
.flaticon-google-play:before { content: "\f11f"; }
.flaticon-list:before { content: "\f120"; }
.flaticon-factory:before { content: "\f121"; }
.flaticon-bullhorn:before { content: "\f122"; }
.flaticon-loan:before { content: "\f123"; }
.flaticon-lecture:before { content: "\f124"; }
.flaticon-play:before { content: "\f125"; }
.flaticon-book:before { content: "\f126"; }
.flaticon-book-1:before { content: "\f127"; }
.flaticon-register:before { content: "\f128"; }
.flaticon-thumbnails:before { content: "\f129"; }
.flaticon-world:before { content: "\f12a"; }
.flaticon-search:before { content: "\f12b"; }
.flaticon-bag:before { content: "\f12c"; }
.flaticon-cart:before { content: "\f12d"; }
.flaticon-play-button:before { content: "\f12e"; }
.flaticon-ribbon:before { content: "\f12f"; }
.flaticon-book-2:before { content: "\f130"; }
.flaticon-facebook:before { content: "\f131"; }
.flaticon-instagram:before { content: "\f132"; }
.flaticon-youtube:before { content: "\f133"; }
.flaticon-course:before { content: "\f134"; }
.flaticon-envelope:before { content: "\f135"; }
.flaticon-musical-note:before { content: "\f136"; }
.flaticon-next:before { content: "\f137"; }
.flaticon-star:before { content: "\f138"; }
.flaticon-house:before { content: "\f139"; }
.flaticon-heart:before { content: "\f13a"; }
.flaticon-like:before { content: "\f13b"; }
.flaticon-reload:before { content: "\f13c"; }
.flaticon-phone-call:before { content: "\f13d"; }
.flaticon-share:before { content: "\f13e"; }
.flaticon-shopping-cart-1:before { content: "\f13f"; }
.flaticon-quote:before { content: "\f140"; }
.flaticon-vector:before { content: "\f141"; }
.flaticon-code:before { content: "\f142"; }
.flaticon-cms:before { content: "\f143"; }
.flaticon-camera:before { content: "\f144"; }
.flaticon-monitor:before { content: "\f145"; }
.flaticon-contract:before { content: "\f146"; }
.flaticon-music:before { content: "\f147"; }
.flaticon-pin:before { content: "\f148"; }
.flaticon-thinking:before { content: "\f149"; }
.flaticon-calendar-1:before { content: "\f14a"; }
.flaticon-pin-1:before { content: "\f14b"; }
.flaticon-xing-website-logo:before { content: "\f14c"; }
.flaticon-woman:before { content: "\f14d"; }
.flaticon-bookmark:before { content: "\f14e"; }
.flaticon-student:before { content: "\f14f"; }
.flaticon-list-1:before { content: "\f150"; }
.flaticon-yes:before { content: "\f151"; }
.flaticon-phone:before { content: "\f152"; }
.flaticon-skype:before { content: "\f153"; }
.flaticon-link:before { content: "\f154"; }
.flaticon-mail-1:before { content: "\f155"; }
.flaticon-profile:before { content: "\f156"; }
.flaticon-support:before { content: "\f157"; }
.flaticon-clock-1:before { content: "\f158"; }
.flaticon-edit:before { content: "\f159"; }
.flaticon-add-user:before { content: "\f15a"; }
.flaticon-right-arrow:before { content: "\f15b"; }
.flaticon-left-arrow:before { content: "\f15c"; }
.flaticon-right:before { content: "\f15d"; }
.flaticon-linkedin:before { content: "\f15e"; }
.flaticon-twitter:before { content: "\f15f"; }
.flaticon-settings:before { content: "\f160"; }
.flaticon-hand:before { content: "\f161"; }
.flaticon-graduated:before { content: "\f162"; }
.flaticon-online-learning-2:before { content: "\f163"; }
.flaticon-ebook:before { content: "\f164"; }
.flaticon-online-learning-3:before { content: "\f165"; }
.flaticon-video:before { content: "\f166"; }