.course-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  width: 100%;
  max-width: 300px;
  height: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
}

.card-content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
}

/* Default styles for desktop */
.posterShow {
  height: 100%;
  width: 100%;
}
